import styled from "styled-components"

import { Container } from "@components/global"

export const StyledContainer = styled(Container)`
  padding: 8vh 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr;
  grid-column-gap: 5vw;
  grid-row-gap: 0px;

  @media (max-width: ${props => props.theme.screen.sm}) {
    display: flex;
    flex-flow: column;
    padding: 8vh 1rem;
  }
`

export const Title = styled.h2`
  ${props => props.theme.font_size.large};
  margin-bottom: 4rem;
  font-weight: 700;
  user-select: none;

  @media (max-width: ${props => props.theme.screen.sm}) {
    text-align: center;
  }
`

export const Text = styled.p`
  ${props => props.theme.font_size.small};
  text-align: left;
  padding: 0 0 2rem;
`

export const TextWrapper = styled.div``

export const SecondContainer = styled(Container)`
  padding: 8vh 0;

  @media (max-width: ${props => props.theme.screen.sm}) {
    padding: 8vh 1rem;
  }
`
