import styled from "styled-components"

import { Container } from "@components/global"

export const SectionFeatures = styled.section`
  position: relative;
  overflow-x: hidden;
  background-color: #ffffff;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='464' height='464' viewBox='0 0 800 800'%3E%3Cg fill='none' stroke='%23fbfdfe' stroke-width='2.8'%3E%3Cpath d='M769 229L1037 260.9M927 880L731 737 520 660 309 538 40 599 295 764 126.5 879.5 40 599-197 493 102 382-31 229 126.5 79.5-69-63'/%3E%3Cpath d='M-31 229L237 261 390 382 603 493 308.5 537.5 101.5 381.5M370 905L295 764'/%3E%3Cpath d='M520 660L578 842 731 737 840 599 603 493 520 660 295 764 309 538 390 382 539 269 769 229 577.5 41.5 370 105 295 -36 126.5 79.5 237 261 102 382 40 599 -69 737 127 880'/%3E%3Cpath d='M520-140L578.5 42.5 731-63M603 493L539 269 237 261 370 105M902 382L539 269M390 382L102 382'/%3E%3Cpath d='M-222 42L126.5 79.5 370 105 539 269 577.5 41.5 927 80 769 229 902 382 603 493 731 737M295-36L577.5 41.5M578 842L295 764M40-201L127 80M102 382L-261 269'/%3E%3C/g%3E%3Cg fill='%23e5f8fc'%3E%3Ccircle cx='769' cy='229' r='12'/%3E%3Ccircle cx='539' cy='269' r='12'/%3E%3Ccircle cx='603' cy='493' r='12'/%3E%3Ccircle cx='731' cy='737' r='12'/%3E%3Ccircle cx='520' cy='660' r='12'/%3E%3Ccircle cx='309' cy='538' r='12'/%3E%3Ccircle cx='295' cy='764' r='12'/%3E%3Ccircle cx='40' cy='599' r='12'/%3E%3Ccircle cx='102' cy='382' r='12'/%3E%3Ccircle cx='127' cy='80' r='12'/%3E%3Ccircle cx='370' cy='105' r='12'/%3E%3Ccircle cx='578' cy='42' r='12'/%3E%3Ccircle cx='237' cy='261' r='12'/%3E%3Ccircle cx='390' cy='382' r='12'/%3E%3C/g%3E%3C/svg%3E");

  .backgroundArt {
    position: absolute;
    top: 5vh;
  }

  .backgroundArt1 {
    position: absolute;
    bottom: 15vh;
    right: 0;
  }
`

export const StyledContainer = styled(Container)`
  padding: 8vh 0;
  text-align: center;
`

export const Title = styled.h2`
  ${props => props.theme.font_size.large};
  margin-bottom: 2rem;
  font-weight: 700;
  user-select: none;
`

export const GridWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 20px 1fr;
  grid-template-rows: 2fr repeat(4, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;

  @media (max-width: ${props => props.theme.screen.sm}) {
    grid-template-columns: 20px 1fr;
    grid-template-rows: 300px repeat(7, 250px);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
  }
`

export const ImgWrapper = styled.div`
  grid-area: 1 / 1 / 2 / 4;
  height: 500px;
  width: auto;
  margin: 0 auto;
  left: 0;
  right: 0;

  .img-features {
    maxwidth: 600px;
    width: 600px;
    height: auto;
  }

  @media (max-width: ${props => props.theme.screen.sm}) {
    grid-area: 1 / 1 / 2 / 3;
    width: auto;
    height: auto;

    .img-features {
      width: 90vw;
      height: auto;
    }
  }
`
export const TextWrapper = styled.div`
  flex-grow: 2;
  ${props => props.theme.font_size.xsmall};
  margin: 0 1rem;
`

export const IconWrapper = styled.div`
  border-radius: 50%;
  background-color: #09beeb;
  width: 100px;
  min-width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3%;

  svg {
    width: 100%;
    height: auto;
  }

  @media (max-width: ${props => props.theme.screen.sm}) {
    width: 75px;
    min-width: 75px;
    height: 75px;
  }
`

export const FeatureWrapper = styled.div`
  display: flex;
  flex-flow: row;
  align-items: center;
`
export const BorderTrace = styled.div`
  grid-area: 1 / 2 / 6 / 3;
  border-image-source: url("./../../../../static/dots.svg");
  border-image-slice: 33% 33%;
  border-image-repeat: round;
  width: 100%;

  @media (max-width: ${props => props.theme.screen.sm}) {
    grid-area: 2 / 1 / 10 / 2;
  }
`

export const BorderTraceVertical = styled(BorderTrace)`
  border-right: 15px dotted #69d7fe;
  justify-self: center;
  width: 15px;
  margin-top: 450px;
  margin-bottom: 118px;

  @media (max-width: ${props => props.theme.screen.sm}) {
    margin: 0;
    justify-self: end;
    margin-bottom: 118px;
  }
`

export const BorderTraceHorizontal = styled(BorderTrace)`
  border-top: 15px dotted #69d7fe;
  margin: 0 0.8rem;
  min-width: 220px;
  max-width: 220px;

  @media (max-width: ${props => props.theme.screen.sm}) {
    min-width: 20px;
    max-width: 20px;
  }
`

export const FeatureWrapper1 = styled(FeatureWrapper)`
  grid-area: 2 / 1 / 3 / 2;
  align-self: center;
  flex-flow: row-reverse;
  text-align: right;

  @media (max-width: ${props => props.theme.screen.sm}) {
    grid-area: 2 / 2 / 3 / 3;
    flex-flow: row;
    text-align: left;
  }
`

export const FeatureWrapper2 = styled(FeatureWrapper)`
  grid-area: 2 / 3 / 3 / 4;
  align-self: center;
  text-align: left;

  @media (max-width: ${props => props.theme.screen.sm}) {
    grid-area: 3 / 2 / 4 / 3;
  }
`

export const FeatureWrapper3 = styled(FeatureWrapper)`
  grid-area: 3 / 1 / 4 / 2;
  align-self: center;
  flex-flow: row-reverse;
  text-align: right;

  @media (max-width: ${props => props.theme.screen.sm}) {
    grid-area: 4 / 2 / 5 / 3;
    text-align: left;
    flex-flow: row;
  }
`

export const FeatureWrapper4 = styled(FeatureWrapper)`
  grid-area: 3 / 3 / 4 / 4;
  align-self: end;
  text-align: left;

  @media (max-width: ${props => props.theme.screen.sm}) {
    grid-area: 5 / 2 / 6 / 3;
    align-self: center;
  }
`

export const FeatureWrapper5 = styled(FeatureWrapper)`
  grid-area: 4 / 1 / 5 / 2;
  align-self: center;
  flex-flow: row-reverse;
  text-align: right;

  @media (max-width: ${props => props.theme.screen.sm}) {
    grid-area: 6 / 2 / 7 / 3;
    text-align: left;
    flex-flow: row;
  }
`

export const FeatureWrapper6 = styled(FeatureWrapper)`
  grid-area: 4 / 3 / 5 / 4;
  align-self: end;
  text-align: left;

  @media (max-width: ${props => props.theme.screen.sm}) {
    grid-area: 7 / 2 / 8 / 3;
    align-self: center;
  }
`

export const FeatureWrapper7 = styled(FeatureWrapper)`
  grid-area: 5 / 1 / 6 / 2;
  align-self: center;
  flex-flow: row-reverse;
  text-align: right;

  @media (max-width: ${props => props.theme.screen.sm}) {
    grid-area: 8 / 2 / 9 / 3;
    flex-flow: row;
    text-align: left;
  }
`
