import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

import { ReactComponent as Ambulance } from "@static/icons/ambulance.svg"
import { ReactComponent as BloodAnalysis } from "@static/icons/blood-analysis.svg"
import { ReactComponent as Briefcase } from "@static/icons/briefcase.svg"
import { ReactComponent as Heart } from "@static/icons/heart-beats-lifeline-in-a-heart.svg"
import { ReactComponent as Bed } from "@static/icons/illness-on-bed.svg"
import { ReactComponent as Laptop } from "@static/icons/laptop.svg"
import { ReactComponent as Folders } from "@static/icons/results-folders.svg"
import { ReactComponent as PulsLine } from "@static/pulse_line_1.svg"
import { ReactComponent as PulsLine1 } from "@static/pulse_line_3.svg"

import {
  SectionFeatures,
  StyledContainer,
  Title,
  GridWrapper,
  ImgWrapper,
  IconWrapper,
  TextWrapper,
  FeatureWrapper1,
  FeatureWrapper2,
  FeatureWrapper3,
  FeatureWrapper4,
  FeatureWrapper5,
  FeatureWrapper6,
  FeatureWrapper7,
  BorderTraceHorizontal,
  BorderTraceVertical,
} from "./style"

const Features = () => {
  const data = useStaticQuery(graphql`
    query {
      art: file(
        sourceInstanceName: { eq: "art" }
        name: { eq: "smart_hospital_assets" }
      ) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <SectionFeatures id="features">
      <PulsLine className="backgroundArt" />
      <PulsLine1 className="backgroundArt1" />
      <StyledContainer>
        <Title>SmartHospital Assets</Title>
        <GridWrapper>
          <BorderTraceVertical />
          <ImgWrapper>
            <Img
              fluid={data.art.childImageSharp.fluid}
              className="img-features"
            />
          </ImgWrapper>
          <FeatureWrapper1>
            <BorderTraceHorizontal />
            <IconWrapper>
              <Briefcase />
            </IconWrapper>
            <TextWrapper>
              Manage inventory of medications, injections, disposables, and
              other equipment
            </TextWrapper>
          </FeatureWrapper1>
          <FeatureWrapper2>
            <BorderTraceHorizontal />
            <IconWrapper>
              <Laptop />
            </IconWrapper>
            <TextWrapper>
              Better coordination between physicians from multi-specialties
              allowing patients to receive comprehensive treatment
            </TextWrapper>
          </FeatureWrapper2>
          <FeatureWrapper3>
            <BorderTraceHorizontal />
            <IconWrapper>
              <Heart />
            </IconWrapper>
            <TextWrapper>
              Real-time updates about the availability of doctor’s appointments,
              beds and equipment in the hospital
            </TextWrapper>
          </FeatureWrapper3>
          <FeatureWrapper4>
            <BorderTraceHorizontal />
            <IconWrapper>
              <Folders />
            </IconWrapper>
            <TextWrapper>
              Maintain medical records of patients including their diagnosis,
              reports of lab investigations, and medical history
            </TextWrapper>
          </FeatureWrapper4>
          <FeatureWrapper5>
            <BorderTraceHorizontal />
            <IconWrapper>
              <Bed />
            </IconWrapper>
            <TextWrapper>
              Continuous monitoring of the patient's vital signs
            </TextWrapper>
          </FeatureWrapper5>
          <FeatureWrapper6>
            <BorderTraceHorizontal />
            <IconWrapper>
              <Ambulance />
            </IconWrapper>
            <TextWrapper>
              Real-time access emergency alerts and ability to message the staff
            </TextWrapper>
          </FeatureWrapper6>
          <FeatureWrapper7>
            <BorderTraceHorizontal />
            <IconWrapper>
              <BloodAnalysis />
            </IconWrapper>
            <TextWrapper>
              Integrated system to streamline the hospital’s workflow to improve
              efficiency and productivity
            </TextWrapper>
          </FeatureWrapper7>
        </GridWrapper>
      </StyledContainer>
    </SectionFeatures>
  )
}

export default Features
