import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import BackgroundImage from "gatsby-background-image"

import {
  StyledContainer,
  SecondContainer,
  Title,
  Text,
  TextWrapper,
} from "./style"

const Info = () => {
  const data = useStaticQuery(graphql`
    query {
      background: file(
        sourceInstanceName: { eq: "background" }
        name: { eq: "smart_hospital_info_background" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2560, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      background1: file(
        sourceInstanceName: { eq: "background" }
        name: { eq: "smart_hospital_info_background_2" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2560, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      art: file(
        sourceInstanceName: { eq: "art" }
        name: { eq: "software_deployment_art_2" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1280) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <section id="info">
      <BackgroundImage
        className="biox_sign_details_background"
        Tag="div"
        fluid={data.background.childImageSharp.fluid}
        style={{
          width: "100%",
          height: "auto",
          backgroundPosition: "top center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <StyledContainer>
          <TextWrapper>
            <Title>Monitor Patient's Vital Signs</Title>
            <Text>
              The most essential component of examining a patient is obtaining
              their vital signs. Vital signs are usually recorded at the initial
              moment when the patient is evaluated. In the out-patient
              setting,the vital signs are usually recorded only once. If they
              are abnormal, the patient may be advised to seek emergency medical
              treatment. Research studies have suggested that patients in the
              pre-hospital setting, Emergency Room, General Ward patients, and
              ICU should be monitored in a real-time basis. With our
              SmartHospital device and software package, access to patient`s
              live data is just one click away, without the need for an onsite
              data center.
            </Text>
          </TextWrapper>
          <Img fluid={data.art.childImageSharp.fluid} />
        </StyledContainer>
      </BackgroundImage>
      <BackgroundImage
        className="biox_sign_details_background"
        Tag="div"
        fluid={data.background1.childImageSharp.fluid}
        style={{
          width: "100%",
          height: "auto",
          backgroundPosition: "top center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <SecondContainer>
          <TextWrapper>
            <Title>Superior Healthcare and Resource Management</Title>
            <Text>
              Reduced response time is the key to superior healthcare
              management. It is well documented that earlier intervention leads
              to improved patient outcome. Starting from managing patient’s
              appointments and hospitalization to reporting lab results,
              monitoring vital signs and maintaining medical records,
              SmartHospital is designed to save time, reduce costs, and improve
              efficiency. Our SmartHospital software package is considerably
              easier and cheaper to set up, maintain, and upgrade than onsite
              data centers while providing many additional patient benefits like
              direct messages to nursing staff.
            </Text>
          </TextWrapper>
        </SecondContainer>
      </BackgroundImage>
    </section>
  )
}

export default Info
