import React from "react"
import BackgroundImage from "gatsby-background-image"
import AnchorLink from "react-anchor-link-smooth-scroll"
import { graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"
import CtaButton from "@common/CtaButton"

import {
  StyledContainer,
  Hero,
  ImgWrapper,
  ButtonWrapper,
  Divider,
  Title,
  SubTitle,
} from "./style"

const Header = () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          hero: file(
            sourceInstanceName: { eq: "background" }
            name: { eq: "smart_hospital_hero_background" }
          ) {
            relativePath
            childImageSharp {
              fluid(maxWidth: 2560, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          smartHospital: file(
            sourceInstanceName: { eq: "art" }
            name: { eq: "smart_mockup_1" }
          ) {
            childImageSharp {
              fluid(maxWidth: 600, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      `}
      render={data => (
        <BackgroundImage
          className="hero"
          Tag="section"
          fluid={data.hero.childImageSharp.fluid}
          style={{
            width: "100%",
            backgroundPosition: "top center",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        >
          <Hero>
            <StyledContainer>
              <Title>SmartHospital</Title>
              <SubTitle>Making onsite data centers obsolete</SubTitle>
              <ImgWrapper>
                <Img fluid={data.smartHospital.childImageSharp.fluid} />
              </ImgWrapper>
              <ButtonWrapper>
                <AnchorLink href="#info">
                  <CtaButton text="Learn more" />
                </AnchorLink>
              </ButtonWrapper>
            </StyledContainer>
          </Hero>
          <Divider viewBox="0 0 1920 300" aria-hidden="true">
            <path
              d="M 0 200 Q 480 300 960 200 Q 1440 100 1920 200 L 1920 300 L 0 300 L 0 200"
              fill="#0BAEE7"
            ></path>
            <path
              d="M 0 240 Q 480 300 960 200 Q 1440 100 1920 240 L 1920 300 L 0 300 L 0 200"
              fill="white"
            ></path>
          </Divider>
        </BackgroundImage>
      )}
    />
  )
}

export default Header
