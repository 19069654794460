import React from "react"

import Layout from "@common/Layout"
import Header from "@sections/smartHospital/Header"
import Info from "@sections/smartHospital/Info"
import Features from "@sections/smartHospital/Features"
import SlideShow from "@sections/smartHospital/SlideShow/SlideShow"
import About from "@sections/smartHospital/About"
import Brochure from "@common/Brochure"
import Contact from "@common/Contact"
import Footer from "@common/Footer"

const NAV_ITEMS = ["Info", "Features", "About", "Contact"]

const Software = () => (
  <Layout
    title="SmartHospital"
    color="rgba(10, 180, 233, 0.9)"
    navItems={NAV_ITEMS}
  >
    <Header />
    <Info />
    <Features />
    <SlideShow />
    <About />
    <Brochure />
    <Contact />
    <Footer />
  </Layout>
)

export default Software
