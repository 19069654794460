import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import Img from "gatsby-image"

import {
  StyledContainer,
  CardWrapper,
  Card,
  Title,
  CardText,
  Text,
  ImageWrapper,
  DividerWrapper,
  Divider,
} from "./style"

const About = () => {
  const data = useStaticQuery(graphql`
    query {
      background: file(
        sourceInstanceName: { eq: "background" }
        name: { eq: "smart_hospital_about_background" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2560, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      art: file(
        sourceInstanceName: { eq: "art" }
        name: { eq: "smart_mockup_2" }
      ) {
        childImageSharp {
          fluid(maxWidth: 1200, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <section id="about">
      <BackgroundImage
        className="biox_sign_details_background"
        Tag="section"
        fluid={data.background.childImageSharp.fluid}
        style={{
          width: "100%",
          height: "auto",
          backgroundPosition: "center center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <StyledContainer>
          <CardWrapper>
            <Card>
              <Title>Why SmartHospital?</Title>
              <CardText>
                <Text>
                  As clinical research points out the continuous nature of the
                  monitoring provides an ongoing picture of the patient's
                  condition rather than typical monitoring by a staff member,
                  which measures only episodically without the ability to trend
                  data. As a result, some early deterioration signals can be
                  missed as they occur between the times that the staff measures
                  the patient's vital signs. Also there are several key benefits
                  to monitoring a patient’s vital signs remotely.
                </Text>
                <Text>
                  The most obvious being that the clinician can evaluate a
                  patient without being in front of them. This allows for more
                  frequent evaluation, with less risk of infection transmission
                  in cases of pandemic, or a faster doctor response in
                  situations of clinical emergencies or overcrowded hospitals.
                  This will vastly improve the outcome of the treatments and
                  enhance patient satisfaction.
                </Text>
              </CardText>
            </Card>
          </CardWrapper>
          <ImageWrapper>
            <Img fluid={data.art.childImageSharp.fluid} />
          </ImageWrapper>
        </StyledContainer>
        <DividerWrapper>
          <Divider viewBox="0 0 1920 650" aria-hidden="true">
            <path
              d="M 960 200 Q 480 0 0 200 L 0 650 L 1920 650 L 1920 200 Q 1440 400 960 200"
              fill="#84D6F3"
            ></path>
            <path
              d="M 960 200 Q 480 0 0 240 L 0 650 L 1920 650 L 1920 240 Q 1440 400 960 200 "
              fill="#0BAEE7"
            ></path>
          </Divider>
        </DividerWrapper>
      </BackgroundImage>
    </section>
  )
}

export default About
