import styled from "styled-components"

import { Container } from "@components/global"

export const StyledContainer = styled(Container)`
  padding: 8vh 0;
`

export const Title = styled.h2`
  ${props => props.theme.font_size.large};
  margin-bottom: 2rem;
  font-weight: 700;
  user-select: none;
  color: #fff;
`

export const Text = styled.p`
  ${props => props.theme.font_size.xsmall};
  text-align: left;
  padding: 0 0 2rem;
  color: #fff;
`

export const CardWrapper = styled.div`
  display: flex;
  margin-top: 5vh;
`

export const Card = styled.div`
  box-shadow: 0px 0px 25px -2px rgba(0, 255, 255, 0.45);
  border-radius: 30px;
  padding: 3rem 2rem;
  background: #09bfec;
`

export const CardText = styled.div``

export const ImageWrapper = styled.div`
  width: 80%;
  max-width: 1200px;
  margin: 0 auto;
  margin-top: 5vh;
`
export const DividerWrapper = styled.div`
  position: relative;

  @media (max-width: ${props => props.theme.screen.sm}) {
    display: none;
  }
`
export const Divider = styled.svg`
  display: block;
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: auto;
  z-index: -1;
`
